<template>
    <div>
        <Quote title="Bu yerda siz parolni tiklashingiz mumkin"/>
        <GoBack next="Parolni tiklash so'rovi"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-9 col-md-8">
                <form v-if="!isEmailSent" @submit.prevent="reset" id="emailInput ">
                    <div class="mt-3">
                        <p>
                            Elektron pochta manzilingizni kiriting va
                            biz sizga parolingizni tiklash uchun havola yuboramiz.
                        </p>
                    </div>
                    <div id="input">
                        <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email"
                            v-model="form.email"
                            @error="errors"
                        />
                    </div>

                    <div class="d-block d-md-flex justify-content-between align-items-center">
                        <div
                            class="d-none d-md-flex mr-2"
                            style="font-size: 15px"
                            @click="scrollToTop()"
                        >
                            <p class="mt-3 mt-md-0">
                                Agar hali ro'yxatdan o'tmagan bo'lsangiz,
                                <router-link to="/registration" class="hover-decoration">ro'yxatdan o'ting.
                                </router-link>
                            </p>
                        </div>
                        <div class="row d-none d-md-flex justify-content-left col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                type="submit"
                                class="btn btn-primary"
                            >
                                Yuborish
                            </button>
                        </div>
                        <!--ekran md da button col-12 bo`ladi-->
                        <div class="row d-flex d-md-none justify-content-left col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                type="submit"
                                class="btn btn-primary"
                            >
                                Yuborish
                            </button>
                        </div>
                        <div
                            class="d-flex d-md-none"
                            style="font-size: 15px"
                            @click="scrollToTop()"
                        >
                            <p class="mt-3 mt-md-0">
                                Agar hali ro'yxatdan o'tmagan bo'lsangiz,
                                <router-link to="/registration" class="hover-decoration">ro'yxatdan o'ting.
                                </router-link>
                            </p>
                        </div>
                    </div>
                </form>
                <div v-if="isEmailSent" class="mt-3">
                    <p>
                        Parolni tiklash uchun havola elektron pochtangizga yuborildi. Havolani amal qilish muddati 1
                        soat. Agar sizga xat bormagan bo'lsa, spam papkasini tekshiring yoki
                        <router-link to="/login" class="hover-decoration">qayta urining.</router-link>
                    </p>
                </div>
                <div>
                    <b-modal ref="modal-non-available-user-error" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Bunday foydalanuvchi mavjud emas!</h3>
                        </div>
                        <b-button
                            class="mt-3"
                            variant="btn btn-primary"
                            block @click="hideNonAvailableUserModal"
                        >
                            Qayta urinish
                        </b-button>
                    </b-modal>
                </div>

                <div>
                    <b-modal ref="modal-error-connection" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Internet bilan aloqa uzildi</h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideErrorConnectionModal"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions} from "vuex"

export default {
    name: "Login",
    components: {GoBack, Quote},
    data() {
        return {
            errors: {
                email: null
            },
            form: {
                email: "",
            },
            isEmailSent: false,
            isLoading: false,
        }
    },
    methods: {
        ...mapActions(['fetchRequestResetPassword']),
        reset() {
            this.isLoading = true;
            this.fetchRequestResetPassword({email: this.form.email})
            .then(() => {
                this.isLoading = false;
                this.isEmailSent =true;
            })
            .catch((reason) => {
                if (reason === 'connectionRefused') {
                    this.$refs['modal-error-connection'].show()
                    setTimeout(this.hideErrorConnectionModal, 5000)
                } else {
                    this.$refs['modal-non-available-user-error'].show()
                    setTimeout(this.hideNonAvailableUserModal, 5000)
                }

                this.isLoading = false;
            })
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        hideErrorConnectionModal() {
            this.$refs['modal-error-connection'].hide()
        },
        hideNonAvailableUserModal() {
            this.$refs['modal-non-available-user-error'].hide()
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

#emailInput {
    /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: center;
}

#input {
    left: 0;
    top: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #A6A6A6;
    background: #FFFFFF;
}

#exampleInputEmail1, #exampleInputPassword1 {
    border: 1px solid #80007F;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 10px !important;
}

.justify-content-left .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    background-color: #80007F !important;
    border-color: #80007F;
    outline: none !important;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098 !important;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}
</style>
